export const mapEnumValorParaDescricao = (enumerador, valor) => {
  const enumMap = enumerador.reduce((map, item) => {
    map[item.id] = item.descricao;
    return map;
  }, {});

  return enumMap[valor] || "";
};

export const mapEnumValorParaBadge = (enumerador, valor) => {
  const enumMap = enumerador.reduce((map, item) => {
    map[item.id] = item.badge;
    return map;
  }, {});

  return enumMap[valor] || "";
};

export const getEnum = (enumerador, valor) => {
  const enumObject = enumerador.find((filter) => filter.id === valor);
  return enumObject;
};
export const getEnumDescription = (enumerador, valor) => {
  const enumObject = enumerador.find((filter) => filter.id === valor);
  return enumObject?.descricao;
};
export enum TipoEdicaoRegistro {
  novo = 1,
  editar = 2,
  deletar = 3,
}
export enum TipoSituacaoOrdemServico {
  Editando = 1,
  AguardandoAutorizacao = 2,
  Autorizado = 3,
  NaoIniciado = 10,
  Iniciado = 11,
  Pausado = 12,
  Finalizado = 13,
  AguardandoConferencia = 20,
  Reprovado = 21,
  Aprovado = 22,
  Liberado = 98,
  Cancelado = 99,
  Faturado = 100,
}
export enum TipoSituacaoOrcamento {
  Editando = 1,
  AguardandoAprovacacao = 2,
  Expirado = 3,
  Cancelado = 4,
  Reprovado = 5,
  Aprovado = 6,
}

export enum TipoParametro {
  Texto = 1,
  Data = 2,
  Email = 3,
  Consulta = 4,
  Inteiro = 5,
  Numero = 6,
  TextoLongo = 7,
  Senha = 8,
  SimNao = 9,
  Opcoes = 10,
  Monetario = 11,
  Percentual = 12,
  Telefone = 13,
  TelefoneMovel = 14,
  PesquisaRapida = 15,
  MultiplaSelecao = 16,
}

export enum TipoTamanhoColuna {
  Nenhum = 0,
  Tamanho1 = 1,
  Tamanho2 = 2,
  Tamanho3 = 3,
  Tamanho4 = 4,
  Tamanho5 = 5,
  Tamanho6 = 6,
  Tamanho7 = 7,
  Tamanho8 = 8,
  Tamanho9 = 9,
  Tamanho10 = 10,
  Tamanho11 = 11,
  Tamanho12 = 12,
}

export const situacaoEnum = [
  {
    id: 1,
    descricao: "Ativo",
    badge: "badge-primary-subtle",
  },
  {
    id: 2,
    descricao: "Inativo",
    badge: "badge-warning-subtle",
  },
  {
    id: 3,
    descricao: "Todos",
    badge: "badge-success-subtle",
  },
];

export const pessoaEnum = [
  {
    id: 1,
    descricao: "Física",
  },
  {
    id: 2,
    descricao: "Jurídica",
  },
];

export const estadoEnum = [
  {
    id: "AC",
    descricao: "Acre",
  },
  {
    id: "AL",
    descricao: "Alagoas",
  },
  {
    id: "AP",
    descricao: "Amapá",
  },
  {
    id: "AM",
    descricao: "Amazonas",
  },
  {
    id: "BA",
    descricao: "Bahia",
  },
  {
    id: "CE",
    descricao: "Ceará",
  },
  {
    id: "DF",
    descricao: "Distrito Federal",
  },
  {
    id: "ES",
    descricao: "Espírito Santo",
  },
  {
    id: "GO",
    descricao: "Goiás",
  },
  {
    id: "MA",
    descricao: "Maranhão",
  },
  {
    id: "MT",
    descricao: "Mato Grosso",
  },
  {
    id: "MS",
    descricao: "Mato Grosso do Sul",
  },
  {
    id: "MG",
    descricao: "Minas Gerais",
  },
  {
    id: "PA",
    descricao: "Pará",
  },
  {
    id: "PB",
    descricao: "Paraíba",
  },
  {
    id: "PR",
    descricao: "Paraná",
  },
  {
    id: "PE",
    descricao: "Pernambuco",
  },
  {
    id: "PI",
    descricao: "Piauí",
  },
  {
    id: "RJ",
    descricao: "Rio de Janeiro",
  },
  {
    id: "RN",
    descricao: "Rio Grande do Norte",
  },
  {
    id: "RS",
    descricao: "Rio Grande do Sul",
  },
  {
    id: "RO",
    descricao: "Rondônia",
  },
  {
    id: "RR",
    descricao: "Roraima",
  },
  {
    id: "SC",
    descricao: "Santa Catarina",
  },
  {
    id: "SP",
    descricao: "São Paulo",
  },
  {
    id: "SE",
    descricao: "Sergipe",
  },
  {
    id: "TO",
    descricao: "Tocantins",
  },
];
export const estadoIBGEEnum = [
  {
    id: 12,
    descricao: "Acre",
  },
  {
    id: 27,
    descricao: "Alagoas",
  },
  {
    id: 13,
    descricao: "Amapá",
  },
  {
    id: 16,
    descricao: "Amazonas",
  },
  {
    id: 29,
    descricao: "Bahia",
  },
  {
    id: 23,
    descricao: "Ceará",
  },
  {
    id: 53,
    descricao: "Distrito Federal",
  },
  {
    id: 32,
    descricao: "Espírito Santo",
  },
  {
    id: 52,
    descricao: "Goiás",
  },
  {
    id: 21,
    descricao: "Maranhão",
  },
  {
    id: 51,
    descricao: "Mato Grosso",
  },
  {
    id: 50,
    descricao: "Mato Grosso do Sul",
  },
  {
    id: 31,
    descricao: "Minas Gerais",
  },
  {
    id: 15,
    descricao: "Pará",
  },
  {
    id: 25,
    descricao: "Paraíba",
  },
  {
    id: 41,
    descricao: "Paraná",
  },
  {
    id: 26,
    descricao: "Pernambuco",
  },
  {
    id: 22,
    descricao: "Piauí",
  },
  {
    id: 33,
    descricao: "Rio de Janeiro",
  },
  {
    id: 24,
    descricao: "Rio Grande do Norte",
  },
  {
    id: 43,
    descricao: "Rio Grande do Sul",
  },
  {
    id: 11,
    descricao: "Rondônia",
  },
  {
    id: 14,
    descricao: "Roraima",
  },
  {
    id: 42,
    descricao: "Santa Catarina",
  },
  {
    id: 35,
    descricao: "São Paulo",
  },
  {
    id: 28,
    descricao: "Sergipe",
  },
  {
    id: 17,
    descricao: "Tocantins",
  },
];
export const contatoEnum = [
  {
    id: 1,
    descricao: "Outro",
  },
  {
    id: 2,
    descricao: "Gerente",
  },
  {
    id: 3,
    descricao: "Diretor",
  },
];

export const portalEnum = [
  {
    id: 1,
    descricao: "DNIT",
    engenheiro: true,
    titulo: "Departamento Nacional de Infraestrutura de Transportes",
  },
  {
    id: 2,
    descricao: "SINFRA",
    engenheiro: true,
    titulo: "Secretaria de Estado de Infraestrutura e Logística - Mato Grosso",
  },
  {
    id: 3,
    descricao: "DEINFRA",
    engenheiro: false,
    titulo: "Departamento Estadual de Infraestrutura de Santa Catarina",
  },
  {
    id: 4,
    descricao: "GOINFRA",
    engenheiro: true,
    titulo: "Agência Goiana de Infraestrutura e Transportes",
  },
  {
    id: 5,
    descricao: "DERBA",
    engenheiro: false,
    titulo: "Departamento de Estradas de Rodagem da Bahia",
  },
  {
    id: 6,
    descricao: "DERSP",
    engenheiro: true,
    titulo: "Departamento de Estradas de Rodagem de São Paulo",
  },
  {
    id: 7,
    descricao: "DEERMG",
    engenheiro: false,
    titulo: "Departamento de Edificações e Estradas de Rodagem de Minas Gerais",
  },
  {
    id: 8,
    descricao: "DERES",
    engenheiro: false,
    titulo: "Departamento de Edificações e de Rodovias do Espírito Santo",
  },
  {
    id: 9,
    descricao: "DERRJ",
    engenheiro: false,
    titulo: "Departamento de Estradas de Rodagem do Estado do Rio de Janeiro",
  },
  {
    id: 10,
    descricao: "DERSE",
    engenheiro: true,
    titulo: "Departamento Estadual de Infraestrutura Rodoviária de Sergipe",
  },
  {
    id: 11,
    descricao: "DERPR",
    engenheiro: false,
    titulo: "Departamento de Estradas de Rodagem do Paraná",
  },
  {
    id: 12,
    descricao: "AGESUL",
    engenheiro: false,
    titulo:
      "Agencia Estadual de Gestão de Empreendimentos - Mato Grosso do Sul",
  },
  {
    id: 13,
    descricao: "AGETO",
    engenheiro: false,
    titulo:
      "Agência Tocantinense de Transportes e Obras do Estado do Tocantins",
  },
  {
    id: 14,
    descricao: "DAERRS",
    engenheiro: false,
    titulo: "Departamento Autônomo de Estradas de Rodagem do Rio Grande do Sul",
  },
  {
    id: 15,
    descricao: "DERAL",
    engenheiro: false,
    titulo: "Departamento de Estradas de Rodagem do Estado de Alagoas",
  },
  {
    id: 16,
    descricao: "DERPE",
    engenheiro: false,
    titulo: "Departamento de Estradas de Rodagem de Pernambuco",
  },

  {
    id: 17,
    descricao: "SETRAN",
    engenheiro: false,
    titulo: "Secretaria Executiva de Transportes - Pará",
  },
];

export const tituloParcelaEnum = [
  {
    id: 1,
    descricao: "Pagamento Único",
  },
  {
    id: 2,
    descricao: "Mensal",
  },
  {
    id: 3,
    descricao: "Flexível",
  },
];

export const simNaoEnum = [
  {
    id: false,
    descricao: "Não",
  },
  {
    id: true,
    descricao: "Sim",
  },
];
export const simNaoTodosEnum = [
  {
    id: 0,
    descricao: "Todos",
  },
  {
    id: 1,
    descricao: "Sim",
  },
  {
    id: 2,
    descricao: "Não",
  },
];
export const fusoHorarioEnum = [
  { id: "Dateline Standard Time", descricao: "Horário da Linha de Data" },
  { id: "UTC-11", descricao: "UTC-11" },
  { id: "Aleutian Standard Time", descricao: "Horário das Ilhas Aleutas" },
  { id: "Hawaiian Standard Time", descricao: "Horário do Havaí" },
  { id: "Marquesas Standard Time", descricao: "Horário das Ilhas Marquesas" },
  { id: "Alaskan Standard Time", descricao: "Horário do Alasca" },
  { id: "UTC-09", descricao: "UTC-09" },
  {
    id: "Pacific Standard Time (Mexico)",
    descricao: "Horário do Pacífico (México)",
  },
  { id: "UTC-08", descricao: "UTC-08" },
  { id: "Pacific Standard Time", descricao: "Horário do Pacífico" },
  { id: "US Mountain Standard Time", descricao: "Horário das Montanhas (EUA)" },
  {
    id: "Mountain Standard Time (Mexico)",
    descricao: "Horário das Montanhas (México)",
  },
  { id: "Mountain Standard Time", descricao: "Horário das Montanhas" },
  { id: "Yukon Standard Time", descricao: "Horário de Yukon" },
  {
    id: "Central America Standard Time",
    descricao: "Horário da América Central",
  },
  { id: "Central Standard Time", descricao: "Horário Central" },
  { id: "Easter Island Standard Time", descricao: "Horário da Ilha de Páscoa" },
  {
    id: "Central Standard Time (Mexico)",
    descricao: "Horário Central (México)",
  },
  { id: "Canada Central Standard Time", descricao: "Horário Central (Canadá)" },
  {
    id: "SA Pacific Standard Time",
    descricao: "Horário do Pacífico Sul-Americano",
  },
  {
    id: "Eastern Standard Time (Mexico)",
    descricao: "Horário do Leste (México)",
  },
  { id: "Eastern Standard Time", descricao: "Horário do Leste" },
  { id: "Haiti Standard Time", descricao: "Horário do Haiti" },
  { id: "Cuba Standard Time", descricao: "Horário de Cuba" },
  { id: "US Eastern Standard Time", descricao: "Horário do Leste (EUA)" },
  {
    id: "Turks And Caicos Standard Time",
    descricao: "Horário de Turks e Caicos",
  },
  { id: "Paraguay Standard Time", descricao: "Horário do Paraguai" },
  { id: "Atlantic Standard Time", descricao: "Horário do Atlântico" },
  { id: "Venezuela Standard Time", descricao: "Horário da Venezuela" },
  { id: "Central Brazilian Standard Time", descricao: "Horário de Brasília" },
  {
    id: "SA Western Standard Time",
    descricao: "Horário do Oeste Sul-Americano",
  },
  {
    id: "Pacific SA Standard Time",
    descricao: "Horário do Pacífico Sul-Americano",
  },
  { id: "Newfoundland Standard Time", descricao: "Horário da Terra Nova" },
  { id: "Tocantins Standard Time", descricao: "Horário de Tocantins" },
  {
    id: "E. South America Standard Time",
    descricao: "Horário do Leste Sul-Americano",
  },
  {
    id: "SA Eastern Standard Time",
    descricao: "Horário do Leste Sul-Americano",
  },
  { id: "Argentina Standard Time", descricao: "Horário da Argentina" },
  { id: "Montevideo Standard Time", descricao: "Horário de Montevidéu" },
  { id: "Magallanes Standard Time", descricao: "Horário de Magalhães" },
  { id: "Saint Pierre Standard Time", descricao: "Horário de Saint Pierre" },
  { id: "Bahia Standard Time", descricao: "Horário da Bahia" },
  { id: "UTC-02", descricao: "Horário de Fernando de Noronha" },
  { id: "Greenland Standard Time", descricao: "Horário da Groenlândia" },
  { id: "Mid-Atlantic Standard Time", descricao: "Horário do Atlântico Médio" },
  { id: "Azores Standard Time", descricao: "Horário dos Açores" },
  { id: "Cape Verde Standard Time", descricao: "Horário de Cabo Verde" },
  { id: "UTC", descricao: "UTC" },
  { id: "GMT Standard Time", descricao: "Horário de Greenwich (Londres)" },
  { id: "Greenwich Standard Time", descricao: "Horário de Greenwich" },
  { id: "Sao Tome Standard Time", descricao: "Horário de São Tomé" },
  { id: "Morocco Standard Time", descricao: "Horário do Marrocos" },
  { id: "W. Europe Standard Time", descricao: "Horário da Europa Ocidental" },
  {
    id: "Central Europe Standard Time",
    descricao: "Horário da Europa Central",
  },
  { id: "Romance Standard Time", descricao: "Horário Romance (Paris)" },
  {
    id: "Central European Standard Time",
    descricao: "Horário Padrão da Europa Central",
  },
  {
    id: "W. Central Africa Standard Time",
    descricao: "Horário da África Central Ocidental",
  },
  {
    id: "GTB Standard Time",
    descricao: "Horário da Grécia, Turquia e Bulgária",
  },
  { id: "Middle East Standard Time", descricao: "Horário do Oriente Médio" },
  { id: "Egypt Standard Time", descricao: "Horário do Egito" },
  { id: "E. Europe Standard Time", descricao: "Horário da Europa Oriental" },
  { id: "West Bank Standard Time", descricao: "Horário da Cisjordânia" },
  { id: "South Africa Standard Time", descricao: "Horário da África do Sul" },
  {
    id: "FLE Standard Time",
    descricao: "Horário da Finlândia, Lituânia e Estônia",
  },
  { id: "Israel Standard Time", descricao: "Horário de Israel" },
  { id: "South Sudan Standard Time", descricao: "Horário do Sudão do Sul" },
  { id: "Kaliningrad Standard Time", descricao: "Horário de Kaliningrado" },
  { id: "Sudan Standard Time", descricao: "Horário do Sudão" },
  { id: "Libya Standard Time", descricao: "Horário da Líbia" },
  { id: "Namibia Standard Time", descricao: "Horário da Namíbia" },
  { id: "Jordan Standard Time", descricao: "Horário da Jordânia" },
  { id: "Arabic Standard Time", descricao: "Horário Árabe" },
  { id: "Syria Standard Time", descricao: "Horário da Síria" },
  { id: "Turkey Standard Time", descricao: "Horário da Turquia" },
  { id: "Arab Standard Time", descricao: "Horário Padrão Árabe" },
  { id: "Belarus Standard Time", descricao: "Horário da Bielorrússia" },
  { id: "Russian Standard Time", descricao: "Horário da Rússia (Moscou)" },
  { id: "E. Africa Standard Time", descricao: "Horário da África Oriental" },
  { id: "Volgograd Standard Time", descricao: "Horário de Volgogrado" },
  { id: "Iran Standard Time", descricao: "Horário do Irã" },
  { id: "Arabian Standard Time", descricao: "Horário da Arábia" },
  { id: "Astrakhan Standard Time", descricao: "Horário de Astracã" },
  { id: "Azerbaijan Standard Time", descricao: "Horário do Azerbaijão" },
  { id: "Russia Time Zone 3", descricao: "Horário da Rússia (Samara)" },
  { id: "Mauritius Standard Time", descricao: "Horário das Ilhas Maurício" },
  { id: "Saratov Standard Time", descricao: "Horário de Saratov" },
  { id: "Georgian Standard Time", descricao: "Horário da Geórgia" },
  { id: "Caucasus Standard Time", descricao: "Horário do Cáucaso" },
  { id: "Afghanistan Standard Time", descricao: "Horário do Afeganistão" },
  { id: "West Asia Standard Time", descricao: "Horário da Ásia Ocidental" },
  { id: "Qyzylorda Standard Time", descricao: "Horário de Qyzylorda" },
  { id: "Ekaterinburg Standard Time", descricao: "Horário de Ecaterimburgo" },
  { id: "Pakistan Standard Time", descricao: "Horário do Paquistão" },
  { id: "India Standard Time", descricao: "Horário da Índia" },
  { id: "Sri Lanka Standard Time", descricao: "Horário do Sri Lanka" },
  { id: "Nepal Standard Time", descricao: "Horário do Nepal" },
  { id: "Central Asia Standard Time", descricao: "Horário da Ásia Central" },
  { id: "Bangladesh Standard Time", descricao: "Horário de Bangladesh" },
  { id: "Omsk Standard Time", descricao: "Horário de Omsk" },
  { id: "Myanmar Standard Time", descricao: "Horário de Myanmar" },
  { id: "SE Asia Standard Time", descricao: "Horário do Sudeste Asiático" },
  { id: "Altai Standard Time", descricao: "Horário de Altai" },
  {
    id: "W. Mongolia Standard Time",
    descricao: "Horário do Oeste da Mongólia",
  },
  {
    id: "North Asia Standard Time",
    descricao: "Horário do Norte da Ásia (Krasnoyarsk)",
  },
  {
    id: "N. Central Asia Standard Time",
    descricao: "Horário da Ásia Central do Norte",
  },
  { id: "Tomsk Standard Time", descricao: "Horário de Tomsk" },
  { id: "China Standard Time", descricao: "Horário da China" },
  {
    id: "North Asia East Standard Time",
    descricao: "Horário do Leste da Ásia (Irkutsk)",
  },
  { id: "Singapore Standard Time", descricao: "Horário de Singapura" },
  {
    id: "W. Australia Standard Time",
    descricao: "Horário do Oeste da Austrália",
  },
  { id: "Taipei Standard Time", descricao: "Horário de Taipei" },
  { id: "Ulaanbaatar Standard Time", descricao: "Horário de Ulaanbaatar" },
  {
    id: "Aus Central W. Standard Time",
    descricao: "Horário da Austrália Central Ocidental",
  },
  { id: "Transbaikal Standard Time", descricao: "Horário de Transbaikalia" },
  { id: "Tokyo Standard Time", descricao: "Horário de Tóquio" },
  { id: "North Korea Standard Time", descricao: "Horário da Coreia do Norte" },
  { id: "Korea Standard Time", descricao: "Horário da Coreia" },
  { id: "Yakutsk Standard Time", descricao: "Horário de Yakutsk" },
  {
    id: "Cen. Australia Standard Time",
    descricao: "Horário da Austrália Central",
  },
  {
    id: "AUS Central Standard Time",
    descricao: "Horário da Austrália Central (Darwin)",
  },
  {
    id: "E. Australia Standard Time",
    descricao: "Horário do Leste da Austrália (Brisbane)",
  },
  {
    id: "AUS Eastern Standard Time",
    descricao: "Horário do Leste da Austrália (Sydney)",
  },
  {
    id: "West Pacific Standard Time",
    descricao: "Horário do Pacífico Ocidental",
  },
  { id: "Tasmania Standard Time", descricao: "Horário da Tasmânia" },
  { id: "Vladivostok Standard Time", descricao: "Horário de Vladivostok" },
  { id: "Lord Howe Standard Time", descricao: "Horário de Lord Howe" },
  { id: "Bougainville Standard Time", descricao: "Horário de Bougainville" },
  { id: "Russia Time Zone 10", descricao: "Horário da Rússia (Magadan)" },
  { id: "Magadan Standard Time", descricao: "Horário de Magadan" },
  { id: "Norfolk Standard Time", descricao: "Horário da Ilha Norfolk" },
  { id: "Sakhalin Standard Time", descricao: "Horário de Sacalina" },
  {
    id: "Central Pacific Standard Time",
    descricao: "Horário do Pacífico Central",
  },
  { id: "Russia Time Zone 11", descricao: "Horário da Rússia (Kamchatka)" },
  { id: "New Zealand Standard Time", descricao: "Horário da Nova Zelândia" },
  { id: "UTC+12", descricao: "UTC+12" },
  { id: "Fiji Standard Time", descricao: "Horário de Fiji" },
  { id: "Kamchatka Standard Time", descricao: "Horário de Kamchatka" },
  {
    id: "Chatham Islands Standard Time",
    descricao: "Horário das Ilhas Chatham",
  },
  { id: "UTC+13", descricao: "UTC+13" },
  { id: "Tonga Standard Time", descricao: "Horário de Tonga" },
  { id: "Samoa Standard Time", descricao: "Horário de Samoa" },
  { id: "Line Islands Standard Time", descricao: "Horário das Ilhas Line" },
];

export const motivoEnum = [
  {
    id: 1,
    descricao: "Cancelar Ordem",
  },
  {
    id: 2,
    descricao: "Cancelar Contas a Receber",
  },
  {
    id: 3,
    descricao: "Cancelar Contas a Pagar",
  },
  {
    id: 4,
    descricao: "Cancelar Orçamento",
  },
  {
    id: 5,
    descricao: "Cancelar Crédito",
  },
  {
    id: 6,
    descricao: "Gerar Crédito",
  },
];

export const servicoEnum = [
  {
    id: 1,
    descricao: "AET Federal",
  },
  {
    id: 2,
    descricao: "AET Estadual",
  },
  {
    id: 3,
    descricao: "ANTT",
  },
  {
    id: 4,
    descricao: "Projeto Técnico",
  },
];

export const categoriaEnum = [
  {
    id: 1,
    descricao: "Debito",
    badge: "badge-danger-subtle",
  },
  {
    id: 2,
    descricao: "Credito",
    badge: "badge-success-subtle",
  },
];

export const situacaoInviteEnum = [
  {
    id: 1,
    descricao: "Enviado",
    badge: "badge-primary-subtle",
  },
  {
    id: 2,
    descricao: "Aceito",
    badge: "badge-success-subtle",
  },
  {
    id: 3,
    descricao: "Recusado",
    badge: "badge-warning-subtle",
  },
  {
    id: 4,
    descricao: "Expirado",
    badge: "badge-danger-subtle",
  },
];

export const metodoPagamentoEnum = [
  {
    id: 1,
    descricao: "Dinheiro",
  },
  {
    id: 2,
    descricao: "Pix",
  },
  {
    id: 3,
    descricao: "Transferencia Bancaria",
  },
  {
    id: 4,
    descricao: "Cartao de Credito",
  },
  {
    id: 5,
    descricao: "Cartao de Debito",
  },
  {
    id: 6,
    descricao: "Boleto",
  },
];

export const contaEnum = [
  {
    id: 1,
    descricao: "Corrente",
  },
  {
    id: 2,
    descricao: "Poupanca",
  },
  {
    id: 3,
    descricao: "Caixinha",
  },
];

export const planoPagamentoEnum = [
  {
    id: 1,
    descricao: "À Vista",
  },
  {
    id: 2,
    descricao: "A Prazo",
  },
  {
    id: 3,
    descricao: "Flexível",
  },
];
export const descontoAcrescimoEnum = [
  {
    id: 1,
    descricao: "Desconto",
  },
  {
    id: 2,
    descricao: "Acrescimo",
  },
];
export const entradaEnum = [
  {
    id: 1,
    descricao: "Fixa",
  },
  {
    id: 2,
    descricao: "Flexível",
  },
];
export const quantidadeParcelaEnum = [];
export const diaFixoEnum = [];

export const ordemEnum = [
  {
    id: 1,
    descricao: "Normal",
  },
  {
    id: 2,
    descricao: "Bonificada",
  },
];

export const ordemSituacaoEnum = [
  {
    id: 1,
    descricao: "Editando",
    badge: "editando-badge",
  },
  {
    id: 2,
    descricao: "Aguardando Autorização",
    badge: "aguardando-autorizacao-badge",
  },
  {
    id: 3,
    descricao: "Autorizado",
    badge: "autorizado-badge",
  },
  {
    id: 10,
    descricao: "Não Iniciado",
    badge: "nao-iniciado-badge",
  },
  {
    id: 11,
    descricao: "Iniciado",
    badge: "iniciado-badge",
  },
  {
    id: 12,
    descricao: "Pausado",
    badge: "pausado-badge",
  },
  {
    id: 13,
    descricao: "Finalizado",
    badge: "finalizado-badge",
  },
  {
    id: 20,
    descricao: "Aguardando Conferência",
    badge: "aguardando-conferencia-badge",
  },
  {
    id: 21,
    descricao: "Reprovado",
    badge: "reprovado-badge",
  },
  {
    id: 22,
    descricao: "Aprovado",
    badge: "aprovado-badge",
  },
  {
    id: 98,
    descricao: "Liberado",
    badge: "liberado-badge",
  },
  {
    id: 99,
    descricao: "Cancelado",
    badge: "cancelado-badge",
  },
  {
    id: 100,
    descricao: "Faturado",
    badge: "faturado-badge",
  },
];

export const responsavelFinanceiroEnum = [
  {
    id: true,
    descricao: "O Próprio Cliente",
  },
  {
    id: false,
    descricao: "Outro Cliente",
  },
];

export const timeEnum = [
  {
    id: 1,
    descricao: "Supervisor",
  },
  {
    id: 2,
    descricao: "Agente",
  },
];

export const novaEmpresaEnum = [
  {
    id: 1,
    descricao: "Vou utilizar em meu nome",
  },
  {
    id: 2,
    descricao: "Vou utilizar em nome da minha empresa",
  },
];

export const emailCadastradoEnum = [
  {
    id: 1,
    descricao:
      "Vou utilizar este mesmo e-mail e irei adicionar este novo cadastro como um novo acesso\\permissão",
  },
  {
    id: 2,
    descricao: "Vou informar um novo e-mail",
  },
];

export const parcelamentoEnum = [];

export const direcaoEnum = [
  {
    id: 1,
    descricao: "Hidráulica",
  },
  {
    id: 2,
    descricao: "Mecânica",
  },
];
export const tracaoEnum = [
  {
    id: 1,
    descricao: "Simples 4X2",
    guindaste: false,
  },
  {
    id: 2,
    descricao: "Simples 4x4",
    guindaste: false,
  },
  {
    id: 3,
    descricao: "Simples 6X2",
    guindaste: false,
  },
  {
    id: 4,
    descricao: "Simples 8X2",
    guindaste: false,
  },
  {
    id: 5,
    descricao: "Dupla 6X4",
    guindaste: false,
  },
  {
    id: 6,
    descricao: "Dupla 8X4",
    guindaste: false,
  },
  {
    id: 7,
    descricao: "Tripla 8X6",
    guindaste: false,
  },
  {
    id: 8,
    descricao: "Quádrupla 8X8",
    guindaste: false,
  },
  // Tipos exclusivos para guindaste
  {
    id: 9,
    descricao: "Especial 10X8",
    guindaste: true,
  },
  {
    id: 10,
    descricao: "Especial 12X8",
    guindaste: true,
  },
  {
    id: 11,
    descricao: "Especial 12X12",
    guindaste: true,
  },
  {
    id: 12,
    descricao: "Especial 14X12",
    guindaste: true,
  },
  {
    id: 13,
    descricao: "Especial 16X12",
    guindaste: true,
  },
  {
    id: 14,
    descricao: "Especial 16X16",
    guindaste: true,
  },
];

export const carroceriaEnum = [
  { id: 1, descricao: "Aberta" },
  { id: 2, descricao: "Basculante" },
  { id: 3, descricao: "Boiadeiro" },
  { id: 4, descricao: "Canavieiro" },
  { id: 5, descricao: "Container" },
  { id: 6, descricao: "Fechada" },
  { id: 7, descricao: "Mecânico Operacional" },
  { id: 8, descricao: "Plataforma" },
  { id: 9, descricao: "Prancha" },
  { id: 10, descricao: "Cegonha" },
  { id: 11, descricao: "Rodo Caçamba" },
  { id: 12, descricao: "Rollon Rollof" },
  { id: 13, descricao: "Silo" },
  { id: 14, descricao: "Subestação Móvel" },
  { id: 15, descricao: "Tanque" },
  { id: 16, descricao: "TransTora" },
  { id: 17, descricao: "VTAV" },
];

export const tituloSituacaoEnum = [
  {
    id: 1,
    descricao: "A Vencer",
    badge: "avencer-badge",
  },
  {
    id: 2,
    descricao: "Atrasado",
    badge: "atrasado-badge",
  },
  {
    id: 4,
    descricao: "Pago",
    badge: "pago-badge",
  },
  {
    id: 8,
    descricao: "Estornado",
    badge: "estornado-badge",
  },
  {
    id: 16,
    descricao: "Cancelado",
    badge: "cancelado-badge",
  },
];

export const diferencaTituloEnum = [
  {
    id: 1,
    descricao:
      "Deverá ser considerado um desconto financeiro o montante restante",
  },
  {
    id: 2,
    descricao: "Vou gerar um novo título com a diferença de valor",
  },
];
export const RNTRCEnum = [
  {
    id: 1,
    descricao: "TAC - Transportador Autônomo de Cargas",
    fisica: true,
    juridica: false,
  },
  {
    id: 2,
    descricao: "ETC - Empresa de Transporte Rodoviário de Cargas",
    fisica: false,
    juridica: true,
  },
  {
    id: 3,
    descricao: "CTC - Cooperativa de Transporte Rodoviário de Cargas",
    fisica: false,
    juridica: true,
  },
];
export const carretaEnum = [
  {
    id: 1,
    descricao: "Reboque",
  },
  {
    id: 2,
    descricao: "Semi-Reboque",
  },
  {
    id: 3,
    descricao: "Dolly",
  },
];
export const tratorEnum = [
  {
    id: 1,
    descricao: "Trator",
  },
  {
    id: 2,
    descricao: "Caminhão",
  },
  {
    id: 3,
    descricao: "Guindaste",
  },
  {
    id: 4,
    descricao: "Caminhão Munck",
  },
];
export const corEnum = [
  {
    id: 1,
    descricao: "Azul",
  },
  {
    id: 2,
    descricao: "Branco",
  },
  {
    id: 3,
    descricao: "Amarela/Preta",
  },
  {
    id: 4,
    descricao: "Amarelo",
  },
  {
    id: 5,
    descricao: "Verde",
  },
  {
    id: 6,
    descricao: "Verde/Branca",
  },
  {
    id: 7,
    descricao: "Preto",
  },
  {
    id: 8,
    descricao: "Branco Geada",
  },
  {
    id: 9,
    descricao: "Vermelha/Branca",
  },
  {
    id: 10,
    descricao: "Cinza",
  },
  {
    id: 11,
    descricao: "Prata",
  },
  {
    id: 12,
    descricao: "Bege",
  },
  {
    id: 13,
    descricao: "Fantasia",
  },
  {
    id: 14,
    descricao: "Vermelha",
  },
  {
    id: 15,
    descricao: "Branco Artico",
  },
  {
    id: 16,
    descricao: "Branco com amarelo e preto",
  },
  {
    id: 17,
    descricao: "Laranja/Branco",
  },
  {
    id: 18,
    descricao: "Laranja",
  },
];

export const orcamentoSituacaoEnum = [
  {
    id: 1,
    descricao: "Editando",
    badge: "editando-badge",
  },
  {
    id: 2,
    descricao: "Aguardando Aprovacao",
    badge: "aguardando-aprovacao-badge",
  },
  {
    id: 3,
    descricao: "Expirado",
    badge: "expirado-badge",
  },
  {
    id: 4,
    descricao: "Cancelado",
    badge: "cancelado-badge",
  },
  {
    id: 5,
    descricao: "Reprovado",
    badge: "reprovado-badge",
  },
  {
    id: 6,
    descricao: "Aprovado",
    badge: "aprovado-badge",
  },
];

export const notificacaoEnum = [
  {
    id: 1,
    descricao: "Execução de AET Iniciada",
    badge: "badge-success-subtle",
    badgeDescription: "Iniciado",
  },
  {
    id: 2,
    descricao: "Erro ao Cadastrar AET",
    badge: "badge-danger-subtle",
    badgeDescription: "Erro ao Cadastrar",
  },
  {
    id: 3,
    descricao: "AET Cancelada",
    badge: "badge-warning-subtle",
    badgeDescription: "Cancelada",
  },
  {
    id: 4,
    descricao: "Erro ao Cancelar AET",
    badge: "badge-danger-subtle",
    badgeDescription: "Erro ao Cancelar",
  },
  {
    id: 5,
    descricao: "AET Aguardando Documentação",
    badge: "badge-info-subtle",
    badgeDescription: "Aguardando Documentação",
  },
];

export const situacaoOrgaoEnum = [
  {
    id: 1,
    descricao: "Aguardando Cadastro",
    badge: "aguardando-cadastro-badge",
  },
  {
    id: 2,
    descricao: "Cadastro Temporário",
    badge: "cadastro-temporario-badge",
  },
  {
    id: 3,
    descricao: "Em Análise",
    badge: "em-analise-badge",
  },
  {
    id: 4,
    descricao: "Aguardando Pagamento",
    badge: "aguardando-pagamento-badge",
  },
  {
    id: 5,
    descricao: "Aguardando Análise do Engenheiro",
    badge: "aguardando-engenheiro-badge",
  },
  {
    id: 6,
    descricao: "Aguardando Documentação",
    badge: "aguardando-documentacao-badge",
  },
  {
    id: 7,
    descricao: "Aguardando Formação de Comboio",
    badge: "aguardando-formacao-comboio-badge",
  },
  {
    id: 8,
    descricao: "Em Processo de Liberação",
    badge: "em-processo-liberacao-badge",
  },
  {
    id: 9,
    descricao: "Em Consulta Concessionária/Órgão",
    badge: "em-consulta-concessionaria-orgao-badge",
  },
  {
    id: 10,
    descricao: "Em Consulta Divisão de Planejamento e Projeto",
    badge: "em-consulta-divisao-planejamento-badge",
  },
  {
    id: 11,
    descricao: "Consulta de Viabilidade Concluída",
    badge: "consulta-viabilidade-concluida-badge",
  },
  {
    id: 12,
    descricao: "Analisada Aguardando Pesagem",
    badge: "analisada-aguardando-pesagem-badge",
  },
  {
    id: 13,
    descricao: "Aguardando Correção",
    badge: "aguardando-correcao-badge",
  },
  {
    id: 70,
    descricao: "Verificando Situação no Órgão",
    badge: "verificando-situacao-orgao-badge",
  },
  {
    id: 80,
    descricao: "Fila de Cadastro de Projeto",
    badge: "adicionado-fila-cadastro-projeto-badge",
  },
  {
    id: 81,
    descricao: "Cadastro Projeto Iniciado",
    badge: "cadastro-projeto-iniciado-badge",
  },
  {
    id: 85,
    descricao: "Fila de Download de Documento",
    badge: "adicionado-fila-download-documento-badge",
  },
  {
    id: 86,
    descricao: "Download de Documento Iniciado",
    badge: "download-documento-iniciado-badge",
  },
  {
    id: 90,
    descricao: "Fila de Validação de Pendência",
    badge: "adicionado-fila-validacao-badge",
  },
  {
    id: 91,
    descricao: "Aguardando Geração do Projeto",
    badge: "aguardando-geracao-projeto-badge",
  },
  {
    id: 92,
    descricao: "Aguardando Geração do Laudo",
    badge: "aguardando-geracao-laudo-badge",
  },
  {
    id: 93,
    descricao: "Cadastro Iniciado",
    badge: "cadastro-iniciado-badge",
  },
  {
    id: 94,
    descricao: "Aguardando Atualização de Trecho",
    badge: "aguardando-atualizacao-trecho-badge",
  },
  {
    id: 95,
    descricao: "Fila de Cadastro",
    badge: "adicionado-fila-cadastro-orgao-badge",
  },
  {
    id: 96,
    descricao: "Fila de Cancelamento",
    badge: "adicionado-fila-cancelamento-orgao-badge",
  },
  {
    id: 97,
    descricao: "Pendente",
    badge: "pendente-badge",
  },
  {
    id: 98,
    descricao: "Não Liberada",
    badge: "nao-liberada-badge",
  },
  {
    id: 99,
    descricao: "Cancelada",
    badge: "cancelada-badge",
  },
  {
    id: 100,
    descricao: "Liberado",
    badge: "liberado-badge",
  },
];

export const AetHistoricoEnum = [
  { id: 1, descricao: "Registro Criado", icone: "mdi-file-plus-outline" },
  {
    id: 2,
    descricao: "Registro Alterado",
    icone: "mdi-file-document-edit-outline",
  },
  { id: 3, descricao: "Registro Deletado", icone: "mdi-delete-outline" },
  {
    id: 4,
    descricao: "Adicionado à Fila de Cadastro",
    icone: "mdi-playlist-plus",
  },
  {
    id: 5,
    descricao: "Alterado Para Cadastro Definitivo",
    icone: "mdi-file-check-outline",
  },
  {
    id: 6,
    descricao: "Adicionado à Fila de Envio de Correção",
    icone: "mdi-playlist-edit",
  },
  { id: 7, descricao: "Cadastrado no Órgão", icone: "mdi-account-tie" },
  {
    id: 8,
    descricao: "Cadastrado No Órgão Como Temporário",
    icone: "mdi-clock-outline",
  },
  {
    id: 9,
    descricao: "Adicionado a Fila de Monitoramento",
    icone: "mdi-monitor-eye",
  },
  { id: 10, descricao: "Cancelado", icone: "mdi-cancel" },
  {
    id: 11,
    descricao: "Licença Disponível Para Download",
    icone: "mdi-download",
  },
  {
    id: 12,
    descricao: "Falha ao Obter Documentos Impressos da AET Liberada",
    icone: "mdi-alert-circle-outline",
  },
  {
    id: 13,
    descricao: "Falha ao Enviar Licença Para a Nuvem",
    icone: "mdi-cloud-alert",
  },
  {
    id: 14,
    descricao: "Adicionado à Fila Para Cancelamento",
    icone: "mdi-playlist-remove",
  },
  {
    id: 15,
    descricao: "Adicionado à Fila de Licença Liberada",
    icone: "mdi-playlist-check",
  },
  { id: 16, descricao: "Verificando Situação da AET", icone: "mdi-magnify" },
  { id: 17, descricao: "Download da AET", icone: "mdi-file-download-outline" },
  {
    id: 18,
    descricao: "Download do Boleto",
    icone: "mdi-arrow-down-circle-outline",
  },
  {
    id: 19,
    descricao: "Falha ao Obter Arquivo da Licença",
    icone: "mdi-file-alert-outline",
  },
  {
    id: 20,
    descricao: "Falha ao Obter Arquivo do Boleto",
    icone: "mdi-alert-box-outline",
  },
  {
    id: 21,
    descricao: "Boleto Disponível para Download",
    icone: "mdi-download-box",
  },
  {
    id: 22,
    descricao: "Falha ao Enviar Boleto para a Nuvem",
    icone: "mdi-cloud-off-outline",
  },
  { id: 23, descricao: "Nova Situação", icone: "mdi-information-outline" },
  {
    id: 24,
    descricao: "Sem Alteração de Situação",
    icone: "mdi-minus-circle-outline",
  },
  {
    id: 25,
    descricao: "Verificando Detalhe de Situação",
    icone: "mdi-magnify-plus-outline",
  },
  {
    id: 26,
    descricao: "Detalhe de Correção Solicitada",
    icone: "mdi-alert-outline",
  },
  {
    id: 27,
    descricao: "Documentação Solicitada",
    icone: "mdi-file-document-outline",
  },
  {
    id: 28,
    descricao: "Aguardando Atualização dos Trechos",
    icone: "mdi-update",
  },
  { id: 29, descricao: "Adicionado à Fila de Reenvio", icone: "mdi-repeat" },
  {
    id: 30,
    descricao: "Alterado Para Cadastro Temporário",
    icone: "mdi-clock-check-outline",
  },
  {
    id: 31,
    descricao: "Falha ao Acessar o Portal",
    icone: "mdi-cloud-lock-outline",
  },
  {
    id: 32,
    descricao: "ART Cadastrada",
    icone: "mdi-file-certificate-outline",
  },
  { id: 33, descricao: "ART Já Cadastrada", icone: "mdi-file-check" },
  { id: 34, descricao: "ART Não Disponível", icone: "mdi-file-remove-outline" },
  { id: 35, descricao: "Falha ao Cadastrar ART", icone: "mdi-alert-circle" },
  {
    id: 36,
    descricao: "Aguardando Geração do Projeto",
    icone: "mdi-file-clock-outline",
  },
  {
    id: 37,
    descricao: "Aguardando Geração Laudo Técnico",
    icone: "mdi-file-clock",
  },
  {
    id: 38,
    descricao: "Aguardando Validação de Pendência",
    icone: "mdi-progress-clock",
  },
  {
    id: 39,
    descricao: "Adicionado Fila de Baixar Boleto",
    icone: "mdi-file-download",
  },
  {
    id: 40,
    descricao: "Adicionado Fila de Baixar Licença",
    icone: "mdi-file-certificate",
  },
  {
    id: 41,
    descricao: "Adicionado à Fila Para Obter Dados da AET",
    icone: "mdi-database",
  },
  {
    id: 42,
    descricao: "Falha ao Obter Dados da AET",
    icone: "mdi-alert",
  },
  {
    id: 43,
    descricao: "Dados da AET Atualizados",
    icone: "mdi-database-check",
  },
  {
    id: 44,
    descricao: "Falha ao Atualizar Dados da AET",
    icone: "mdi-database-remove",
  },
  {
    id: 45,
    descricao: "Não Foi Possível Realizar o Cadastro da AET",
    icone: "mdi-database-lock",
  },
  {
    id: 46,
    descricao: "AET Não Autorizada",
    icone: "mdi-alert-octagon-outline",
  },
  {
    id: 47,
    descricao: "Processamento Interno",
    icone: "mdi-progress-check",
  },
  {
    id: 48,
    descricao: "Cadastrado Projeto no Órgão",
    icone: "mdi-office-building-outline",
  },
  {
    id: 49,
    descricao: "Cadastrado Projeto Parcial",
    icone: "mdi-content-save-edit-outline",
  },
  {
    id: 50,
    descricao: "Falha ao Realizar Cadastro do Projeto",
    icone: "mdi-alert-octagram-outline",
  },
  {
    id: 51,
    descricao: "Adicionado à Fila de Cadastro do Projeto",
    icone: "mdi-file-plus-outline",
  },
];

export const opcaoCancelamentoOrdemFaturadaEnum = [
  {
    id: 1,
    descricao: "Gerar crédito para o cliente",
  },
  {
    id: 2,
    descricao: "Cancelar ordem e pagamento",
  },
];
export enum TipoAetHistorico {
  Criado = 1,
  Alterado = 2,
  Deletado = 3,
  AdicionadoFila = 4,
  AlteradoDefinitivo = 5,
  AdicionadoFilaCorrecao = 6,
  CadastradoOrgao = 7,
  CadastradoOrgaoTemporario = 8,
  AdicionadoMonitoramento = 9,
  Cancelado = 10,
  DisponivelDownloadLicenca = 11,
  FalhaObterObterDocumentos = 12,
  FalhaEnviarLicencaNuvem = 13,
  AdicionadoFilaCancelar = 14,
  AdicionadoFilaLiberado = 15,
  VerificandoSituacao = 16,
  DownloadAET = 17,
  DownloadBoleto = 18,
  FalhaObterArquivoLicenca = 19,
  FalhaObterArquivoBoleto = 20,
  DisponivelDownloadBoleto = 21,
  FalhaEnviarBoletoNuvem = 22,
}

export enum TipoSituacaoAetOrgao {
  AguardandoCadastro = 1,
  CadastradoTemporario = 2,
  EmAnalise = 3,
  AguardandoPagamento = 4,
  AguardandoAnaliseEngenheiro = 5,
  AguardandoDocumentacao = 6,
  AguardandoFormacaoComboio = 7,
  EmProcessoLiberacao = 8,
  EmConsultaConcessionariaOrgao = 9,
  EmConsultaDivisaoPlanejamentoProjeto = 10,
  ConsultaViabilidadeConcluida = 11,
  AnalisadaAguardandoPesagem = 12,
  AguardandoCorrecao = 13,

  FilaGeracaoDocumento = 92,
  CadastroIniciado = 93,
  FilaAtualizacaoTrecho = 94,
  FilaCadastro = 95,
  FilaCancelamento = 96,
  Pendente = 97,
  NaoLiberada = 98,
  Cancelada = 99,
  Liberado = 100,
}

export const entidadePessoaEnum = [
  {
    id: 1,
    descricao: "Cliente",
    badge: "badge-primary-subtle",
  },
  {
    id: 2,
    descricao: "Fornecedor",
    badge: "badge-warning-subtle",
  },
  {
    id: 3,
    descricao: "Transportador",
    badge: "badge-success-subtle",
  },
  {
    id: 4,
    descricao: "Engenheiro",
    badge: "badge-info-subtle",
  },
  {
    id: 5,
    descricao: "Motorista",
    badge: "badge-dark-subtle",
  },
  {
    id: 6,
    descricao: "Funcionário",
    badge: "badge-danger-subtle",
  },
  {
    id: 7,
    descricao: "Supervisor",
    badge: "badge-purple-subtle",
  },
  {
    id: 8,
    descricao: "Agente",
    badge: "badge-light-subtle",
  },
  {
    id: 9,
    descricao: "Vendedor",
    badge: "badge-secondary-subtle",
  },
];

export const pneumaticoEnum = [
  { id: 1, descricao: "Convencional" },
  { id: 2, descricao: "Base Extra Larga" },
  { id: 3, descricao: "Convencional + Base Extra Larga" },
];

export const engateEnum = [
  { id: 1, descricao: "Cambão" },
  { id: 2, descricao: "Pescoço" },
];

export const eixoEnum = [
  { id: 1, descricao: "Não Direcionais" },
  { id: 2, descricao: "Direcionais Mecânicos" },
  { id: 3, descricao: "Direcionais Hidráulicos" },
  { id: 4, descricao: "Autodirecionais Hidráulicos" },
  { id: 5, descricao: "Bidirecional" },
  { id: 6, descricao: "Direcionais" },
  { id: 7, descricao: "Direcionais Eletrônicos" },
];
export const creditoEnum = [
  {
    id: 1,
    descricao: "Disponível",
    badge: "badge-info-subtle",
  },
  {
    id: 2,
    descricao: "Utilizado",
    badge: "badge-success-subtle",
  },
  {
    id: 3,
    descricao: "Cancelado",
    badge: "badge-danger-subtle",
  },
];

export const pneuEnum = [
  { id: 1, descricao: "295/80R 22,5" },
  { id: 2, descricao: "275/80R 22,5" },
  { id: 3, descricao: "315/80R 22,5" },
  { id: 4, descricao: "245/70R 19,5" },
  { id: 5, descricao: "235/75R 17,5" },
  { id: 6, descricao: "11R 22,5" },
  { id: 7, descricao: "385/65R 22,5" },
  { id: 8, descricao: "425/65R 22,5" },
];

export const classificacaoDenatranEnum = [
  { id: 50, descricao: "C1" },
  { id: 51, descricao: "C2" },
  { id: 52, descricao: "C3" },
  { id: 53, descricao: "C4" },
  { id: 54, descricao: "C5" },
  { id: 55, descricao: "C6" },
  { id: 56, descricao: "C7" },
  { id: 57, descricao: "C8" },
  { id: 58, descricao: "C9" },
  { id: 100, descricao: "CR1" },
  { id: 101, descricao: "CR1+" },
  { id: 102, descricao: "CR2" },
  { id: 103, descricao: "CR2+" },
  { id: 104, descricao: "CR3" },
  { id: 105, descricao: "CR3+" },
  { id: 106, descricao: "CR4" },
  { id: 107, descricao: "CR4+" },
  { id: 108, descricao: "CR5" },
  { id: 109, descricao: "CR5+" },
  { id: 110, descricao: "CR6" },
  { id: 111, descricao: "CR6+" },
  { id: 112, descricao: "CR7" },
  { id: 113, descricao: "CR7+" },
  { id: 114, descricao: "CR8" },
  { id: 115, descricao: "CR8+" },
  { id: 116, descricao: "CR9" },
  { id: 117, descricao: "CR9+" },
  { id: 118, descricao: "CR10" },
  { id: 119, descricao: "CR10+" },
  { id: 120, descricao: "CR11" },
  { id: 121, descricao: "CR11+" },
  { id: 122, descricao: "CR12" },
  { id: 123, descricao: "CR12+" },
  { id: 124, descricao: "CR13" },
  { id: 125, descricao: "CR13+" },
  { id: 126, descricao: "CRR1+" },
  { id: 127, descricao: "CRR2+" },
  { id: 128, descricao: "CRR3+" },
  { id: 150, descricao: "CTS1" },
  { id: 151, descricao: "CTS2" },
  { id: 152, descricao: "CTS3" },
  { id: 153, descricao: "CTS4" },
  { id: 154, descricao: "CTS5" },
  { id: 155, descricao: "CTS6" },
  { id: 156, descricao: "CTS7" },
  { id: 157, descricao: "CTS8" },
  { id: 158, descricao: "CTS9" },
  { id: 159, descricao: "CTS10" },
  { id: 160, descricao: "CTS11" },
  { id: 161, descricao: "CTS12" },
  { id: 162, descricao: "CTS13" },
  { id: 163, descricao: "CTS14" },
  { id: 164, descricao: "CTS15" },
  { id: 165, descricao: "CTS16" },
  { id: 166, descricao: "CTS17" },
  { id: 167, descricao: "CTS18" },
  { id: 168, descricao: "CTS19" },
  { id: 169, descricao: "CTS20" },
  { id: 170, descricao: "CTS21" },
  { id: 171, descricao: "CTS22" },
  { id: 172, descricao: "CTS23" },
  { id: 173, descricao: "CTS24" },
  { id: 174, descricao: "CTS25" },
  { id: 175, descricao: "CTS26" },
  { id: 176, descricao: "CTS27" },
  { id: 177, descricao: "CTS28" },
  { id: 178, descricao: "CTS29" },
  { id: 200, descricao: "CTSR1" },
  { id: 201, descricao: "CTSR1+" },
  { id: 202, descricao: "CTSR2" },
  { id: 203, descricao: "CTSR2+" },
  { id: 204, descricao: "CTSR3" },
  { id: 205, descricao: "CTSR3+" },
  { id: 206, descricao: "CTSR4" },
  { id: 207, descricao: "CTSR4+" },
  { id: 208, descricao: "CTSR5" },
  { id: 209, descricao: "CTSR5+" },
  { id: 210, descricao: "CTSR6" },
  { id: 211, descricao: "CTSR6+" },
  { id: 212, descricao: "CTSR7" },
  { id: 213, descricao: "CTSR7+" },
  { id: 214, descricao: "CTSR8+" },
  { id: 215, descricao: "CTSRr1+" },
  { id: 216, descricao: "CTSRr2+" },
  { id: 217, descricao: "CTSRr3+" },
  { id: 250, descricao: "CTSS1" },
  { id: 251, descricao: "CTSS1+" },
  { id: 252, descricao: "CTSS2" },
  { id: 253, descricao: "CTSS2+" },
  { id: 254, descricao: "CTSS3" },
  { id: 255, descricao: "CTSS3+" },
  { id: 256, descricao: "CTSS4" },
  { id: 257, descricao: "CTSS4+" },
  { id: 258, descricao: "CTSS5" },
  { id: 259, descricao: "CTSS5+" },
  { id: 260, descricao: "CTSS6" },
  { id: 261, descricao: "CTSS6+" },
  { id: 262, descricao: "CTSS7" },
  { id: 263, descricao: "CTSS7+" },
  { id: 264, descricao: "CTSS8" },
  { id: 265, descricao: "CTSS8+" },
  { id: 266, descricao: "CTSS9" },
  { id: 267, descricao: "CTSS9+" },
  { id: 268, descricao: "CTSS10+" },
  { id: 269, descricao: "CTSS11+" },
  { id: 270, descricao: "CTSS12+" },
  { id: 271, descricao: "CTSS13+" },
  { id: 300, descricao: "CTSSS1+" },
  { id: 301, descricao: "CTSSS2+" },
  { id: 302, descricao: "CTSSS3+" },
  { id: 303, descricao: "CTSSS4+" },
  { id: 304, descricao: "CTSSS5+" },
  { id: 305, descricao: "CTSSS6+" },
  { id: 306, descricao: "CTSSS7+" },
  { id: 307, descricao: "CTSSS8+" },
  { id: 350, descricao: "ORC1" },
  { id: 351, descricao: "ORC2" },
  { id: 352, descricao: "ORC3" },
  { id: 353, descricao: "ORC4" },
  { id: 354, descricao: "ORC5" },
  { id: 355, descricao: "ORC6" },
  { id: 356, descricao: "ORC7" },
  { id: 357, descricao: "ORC8" },
  { id: 358, descricao: "ORC9" },
  { id: 359, descricao: "ORC10" },
  { id: 360, descricao: "ORC11" },
  { id: 361, descricao: "ORC12" },
  { id: 400, descricao: "ORR1" },
  { id: 401, descricao: "ORR2" },
  { id: 402, descricao: "ORR3" },
  { id: 403, descricao: "ORR4" },
  { id: 404, descricao: "ORR5" },
  { id: 405, descricao: "ORR6" },
  { id: 450, descricao: "OU1" },
  { id: 451, descricao: "OU2" },
  { id: 452, descricao: "OU3" },
  { id: 453, descricao: "OU4" },
  { id: 454, descricao: "OU5" },
  { id: 455, descricao: "OU6" },
  { id: 456, descricao: "OU7" },
  { id: 457, descricao: "OU8" },
  { id: 458, descricao: "OU9" },
  { id: 459, descricao: "OU10" },
  { id: 460, descricao: "OU11" },
  { id: 500, descricao: "OUA1" },
  { id: 501, descricao: "OUA1+" },
  { id: 502, descricao: "OUA2" },
  { id: 503, descricao: "OUA2+" },
  { id: 504, descricao: "OUA3" },
  { id: 505, descricao: "OUA3+" },
  { id: 506, descricao: "OUA4" },
  { id: 507, descricao: "OUA4+" },
  { id: 508, descricao: "OUA5" },
  { id: 509, descricao: "OUA5+" },
  { id: 510, descricao: "OUA6" },
  { id: 511, descricao: "OUA6+" },
  { id: 512, descricao: "OUA7" },
  { id: 513, descricao: "OUA7+" },
  { id: 514, descricao: "OUA8" },
  { id: 515, descricao: "OUA8+" },
  { id: 516, descricao: "OUA9" },
  { id: 517, descricao: "OUA9+" },
  { id: 518, descricao: "OUA10" },
  { id: 519, descricao: "OUA10+" },
  { id: 520, descricao: "OUA11" },
  { id: 521, descricao: "OUA11+" },
  { id: 522, descricao: "OUA12" },
  { id: 523, descricao: "OUA12+" },
  { id: 524, descricao: "OUA13" },
  { id: 525, descricao: "OUA13+" },
  { id: 526, descricao: "OUA14" },
  { id: 527, descricao: "OUA15" },
  { id: 550, descricao: "OUB1+" },
  { id: 551, descricao: "OUB2+" },
  { id: 552, descricao: "OUB3+" },
  { id: 553, descricao: "OUB4+" },
  { id: 554, descricao: "OUB5+" },
  { id: 555, descricao: "OUB6+" },
  { id: 556, descricao: "OUB7+" },
  { id: 600, descricao: "OUR1" },
  { id: 601, descricao: "OUR2" },
  { id: 602, descricao: "OUR3" },
  { id: 603, descricao: "OUR4" },
  { id: 604, descricao: "OUR5" },
  { id: 605, descricao: "OUR6" },
  { id: 606, descricao: "OUR7" },
  { id: 607, descricao: "OUR8" },
];
