import React from "react";
import PropTypes from "prop-types";

interface DetailListKPIProps {
  title: string;
  total: number | string;
  details: Array<{
    id: string | number;
    title: string;
    value: string | number;
  }>;
  iconName?: string; // Nome do ícone (ex.: "mdi mdi-star")
  bgColor?: string; // Cor de fundo
  borderColor?: string; // Cor da borda
  titleFontColor?: string; // Cor do título
  totalFontColor?: string; // Cor do total
  detailsFontColor?: string; // Cor dos detalhes
  footerText?: string;
  onDetailClick?: (id: string | number) => void;
}

export function DetailListKPI({
  title,
  total,
  details,
  iconName,
  bgColor = "#ffffff",
  borderColor = "#cccccc",
  titleFontColor = "#000000",
  totalFontColor = "#000000",
  detailsFontColor = "#000000",
  footerText = "",
  onDetailClick,
}: DetailListKPIProps) {
  return (
    <div
      className="p-3 rounded d-flex flex-column position-relative"
      style={{
        backgroundColor: bgColor,
        border: `1px solid ${borderColor}`,
        height: "100%",
        paddingBottom: footerText ? "3rem" : undefined, // Espaço para o footer
        boxSizing: "border-box",
      }}
    >
      {/* Ícone */}
      {iconName && (
        <div className="mb-3 text-center">
          <i className={`${iconName} mdi-36px`} aria-hidden="true"></i>
        </div>
      )}

      {/* Título */}
      <div
        className="text-uppercase text-center font-weight-bold small mb-2"
        style={{ color: titleFontColor }}
      >
        {title}
      </div>

      {/* Total */}
      <div
        className="font-weight-bold text-center display-4 mb-3"
        style={{ color: totalFontColor }}
      >
        {total}
      </div>

      {/* Detalhes */}
      <div className="flex-grow-1">
        {details.map((item, index) => (
          <div
            key={index}
            className="d-flex justify-content-between small mb-1"
            style={{ color: detailsFontColor }}
          >
            <span
              className="font-weight-bold"
              style={{ cursor: onDetailClick ? "pointer" : "default" }}
              onClick={() => onDetailClick && onDetailClick(item.id)}
            >
              {item.title}:
            </span>
            <span
              style={{ cursor: onDetailClick ? "pointer" : "default" }}
              onClick={() => onDetailClick && onDetailClick(item.id)}
            >
              {item.value}
            </span>
          </div>
        ))}
      </div>

      {/* Footer */}
      {footerText && (
        <div
          className="position-absolute text-center py-2 small text-muted"
          style={{
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: bgColor,
            borderTop: `1px solid ${borderColor}`,
            color: detailsFontColor,
          }}
        >
          {footerText}
        </div>
      )}
    </div>
  );
}

// PropTypes (opcional, se você estiver misturando JS/TS)
DetailListKPI.propTypes = {
  title: PropTypes.string.isRequired,
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  details: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      title: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ).isRequired,
  iconName: PropTypes.string,
  bgColor: PropTypes.string,
  borderColor: PropTypes.string,
  titleFontColor: PropTypes.string,
  totalFontColor: PropTypes.string,
  detailsFontColor: PropTypes.string,
  footerText: PropTypes.string,
  onDetailClick: PropTypes.func,
};
