import { lazy } from "react";
import { Loadable } from "../components/organisms/Loadable";

const Dashboard = Loadable(lazy(() => import("../pages/Dashboard")));

const Atividade = Loadable(lazy(() => import("../pages/Atividade")));
const RegistroAtividade = Loadable(
  lazy(() => import("../pages/Atividade/registro"))
);

const Grupo = Loadable(lazy(() => import("../pages/Grupo")));
const RegistroGrupo = Loadable(lazy(() => import("../pages/Grupo/registro")));

const Departamento = Loadable(lazy(() => import("../pages/Departamento")));
const RegistroDepartamento = Loadable(
  lazy(() => import("../pages/Departamento/registro"))
);

const Perfil = Loadable(lazy(() => import("../pages/Autenticacao/Perfil")));

const Motivo = Loadable(lazy(() => import("../pages/Motivo")));
const RegistroMotivo = Loadable(lazy(() => import("../pages/Motivo/registro")));

const Servico = Loadable(lazy(() => import("../pages/Servico")));
const RegistroServico = Loadable(
  lazy(() => import("../pages/Servico/registro"))
);

const Convite = Loadable(lazy(() => import("../pages/Convite")));
const RegistroConvite = Loadable(
  lazy(() => import("../pages/Convite/registro"))
);

const Categoria = Loadable(lazy(() => import("../pages/Categoria")));
const RegistroCategoria = Loadable(
  lazy(() => import("../pages/Categoria/registro"))
);

const Usuario = Loadable(lazy(() => import("../pages/Usuario")));

const Conta = Loadable(lazy(() => import("../pages/Conta")));
const RegistroConta = Loadable(lazy(() => import("../pages/Conta/registro")));

const UsuarioPapel = Loadable(lazy(() => import("../pages/Usuario/papel")));

const PlanoPagamento = Loadable(lazy(() => import("../pages/PlanoPagamento")));
const RegistroPlanoPagamento = Loadable(
  lazy(() => import("../pages/PlanoPagamento/registro"))
);

const ReceitaTitulo = Loadable(lazy(() => import("../pages/Receita")));
const RegistroReceitaTitulo = Loadable(
  lazy(() => import("../pages/Receita/registro"))
);

const ListaReceitaTituloRegistar = Loadable(
  lazy(() => import("../pages/Receita/listaRegistarPagamento"))
);
const ReceitaTituloRegistar = Loadable(
  lazy(() => import("../pages/Receita/registarPagamento"))
);

const ListaReceitaTituloCancelar = Loadable(
  lazy(() => import("../pages/Receita/listaCancelar"))
);
const ReceitaTituloCancelar = Loadable(
  lazy(() => import("../pages/Receita/cancelar"))
);

const ListaReceitaTituloEstornar = Loadable(
  lazy(() => import("../pages/Receita/listaEstornar"))
);
const ReceitaTituloEstornar = Loadable(
  lazy(() => import("../pages/Receita/estornar"))
);

const ListaReceitaTituloConfirmar = Loadable(
  lazy(() => import("../pages/Receita/listaConfirmar"))
);
const ReceitaTituloConfirmar = Loadable(
  lazy(() => import("../pages/Receita/confirmar"))
);

const DespesaTitulo = Loadable(lazy(() => import("../pages/Despesa")));
const RegistroDespesaTitulo = Loadable(
  lazy(() => import("../pages/Despesa/registro"))
);

const ListaDespesaTituloRegistar = Loadable(
  lazy(() => import("../pages/Despesa/listaRegistarPagamento"))
);
const DespesaTituloRegistar = Loadable(
  lazy(() => import("../pages/Despesa/registarPagamento"))
);

const ListaDespesaTituloCancelar = Loadable(
  lazy(() => import("../pages/Despesa/listaCancelar"))
);
const DespesaTituloCancelar = Loadable(
  lazy(() => import("../pages/Despesa/cancelar"))
);

const ListaDespesaTituloEstornar = Loadable(
  lazy(() => import("../pages/Despesa/listaEstornar"))
);
const DespesaTituloEstornar = Loadable(
  lazy(() => import("../pages/Despesa/estornar"))
);

const Ordem = Loadable(lazy(() => import("../pages/Ordem")));
const RegistroOrdem = Loadable(lazy(() => import("../pages/Ordem/registro")));
const OrdemFaturar = Loadable(lazy(() => import("../pages/Ordem/faturar")));
const OrdemCancelar = Loadable(lazy(() => import("../pages/Ordem/cancelar")));

const VeiculoTrator = Loadable(lazy(() => import("../pages/Trator")));
const RegistroTrator = Loadable(lazy(() => import("../pages/Trator/registro")));

const Carreta = Loadable(lazy(() => import("../pages/Carreta")));
const RegistroCarreta = Loadable(
  lazy(() => import("../pages/Carreta/registro"))
);

const Marca = Loadable(lazy(() => import("../pages/Marca")));
const RegistroMarca = Loadable(lazy(() => import("../pages/Marca/registro")));

const FichaTecnica = Loadable(lazy(() => import("../pages/FichaTecnica")));
const RegistroFichaTecnica = Loadable(
  lazy(() => import("../pages/FichaTecnica/registro"))
);

const Filial = Loadable(lazy(() => import("../pages/Filial")));
const RegistroFilial = Loadable(lazy(() => import("../pages/Filial/registro")));

const TabelaPreco = Loadable(lazy(() => import("../pages/TabelaPreco")));
const RegistroTabelaPreco = Loadable(
  lazy(() => import("../pages/TabelaPreco/registro"))
);
const Orcamento = Loadable(lazy(() => import("../pages/Orcamento")));
const RegistroOrcamento = Loadable(
  lazy(() => import("../pages/Orcamento/registro"))
);
const CancelarOrcamento = Loadable(
  lazy(() => import("../pages/Orcamento/cancelar"))
);

const CRMCliente = Loadable(lazy(() => import("../pages/CRM/cliente")));

const AET = Loadable(lazy(() => import("../pages/AET")));
const RegistroAET = Loadable(lazy(() => import("../pages/AET/registro")));
const Relatorio = Loadable(lazy(() => import("../pages/Relatorio")));

const Desenho = Loadable(lazy(() => import("../pages/Teste/Desenho")));
const Teste3 = Loadable(lazy(() => import("../pages/Teste/Teste3")));

const Teste = Loadable(lazy(() => import("../pages/Teste/Teste")));
const Teste2 = Loadable(lazy(() => import("../pages/Teste/Teste2")));
const TesteGabarito = Loadable(lazy(() => import("../pages/Teste/Gabarito")));
const Mapa = Loadable(lazy(() => import("../pages/Teste/Mapa")));
const Trecho = Loadable(lazy(() => import("../pages/Trecho")));
const RegistroTrecho = Loadable(lazy(() => import("../pages/Trecho/registro")));

const Empresa = Loadable(lazy(() => import("../pages/Empresa/registro")));

const Papel = Loadable(lazy(() => import("../pages/Papel")));
const RegistroPapel = Loadable(lazy(() => import("../pages/Papel/registro")));

const ART = Loadable(lazy(() => import("../pages/ART")));
const RegistroART = Loadable(lazy(() => import("../pages/ART/registro")));

const Proibido = Loadable(lazy(() => import("../pages/Erro/Proibido")));

const Configuracao = Loadable(
  lazy(() => import("../pages/Configuracao/registro"))
);

const Pessoa = Loadable(lazy(() => import("../pages/Pessoa")));
const RegistroPessoa = Loadable(lazy(() => import("../pages/Pessoa/registro")));
const Credito = Loadable(lazy(() => import("../pages/Credito")));
const RegistroCredito = Loadable(
  lazy(() => import("../pages/Credito/registro"))
);
const ListaFaturar = Loadable(
  lazy(() => import("../pages/Faturamento/listaFaturar"))
);
const Faturamento = Loadable(lazy(() => import("../pages/Faturamento")));
const RegistroFaturamento = Loadable(
  lazy(() => import("../pages/Faturamento/registro"))
);

const TesteRobert = Loadable(lazy(() => import("../pages/Teste/TesteRobert")));

const autenticadoRoutes = [
  // { path: "/Home", element: <Home /> },//adicionar elemento principal
  { path: "/Dashboard", element: <Dashboard /> },
  { path: "/403", element: <Proibido /> },
  { path: "/CRM/:id", element: <CRMCliente /> },

  { path: "/Teste", element: <Teste /> },
  { path: "/TesteGabarito", element: <TesteGabarito /> },
  { path: "/Teste2", element: <Teste2 /> },
  { path: "/Desenho", element: <Desenho /> },
  { path: "/Teste3", element: <Teste3 /> },
  { path: "/Mapa", element: <Mapa /> },
  { path: "/TesteRobert", element: <TesteRobert /> },

  { path: "/Perfil", element: <Perfil /> },

  { path: "/Atividade", element: <Atividade /> },
  { path: "/Atividade/Novo", element: <RegistroAtividade /> },
  { path: "/Atividade/:id", element: <RegistroAtividade /> },

  { path: "/Grupo", element: <Grupo /> },
  { path: "/Grupo/Novo", element: <RegistroGrupo /> },
  { path: "/Grupo/:id", element: <RegistroGrupo /> },

  { path: "/Departamento", element: <Departamento /> },
  { path: "/Departamento/Novo", element: <RegistroDepartamento /> },
  { path: "/Departamento/:id", element: <RegistroDepartamento /> },
  { path: "/Motivo", element: <Motivo /> },
  { path: "/Motivo/Novo", element: <RegistroMotivo /> },
  { path: "/Motivo/:id", element: <RegistroMotivo /> },
  { path: "/Servico", element: <Servico /> },
  { path: "/Servico/Novo", element: <RegistroServico /> },
  { path: "/Servico/:id", element: <RegistroServico /> },
  { path: "/Convite", element: <Convite /> },
  { path: "/Convite/Novo", element: <RegistroConvite /> },
  { path: "/Convite/:id", element: <RegistroConvite /> },
  { path: "/Categoria", element: <Categoria /> },
  { path: "/Categoria/Novo", element: <RegistroCategoria /> },
  { path: "/Categoria/:id", element: <RegistroCategoria /> },

  { path: "/Usuario", element: <Usuario /> },
  { path: "/Usuario/Papel/:id", element: <UsuarioPapel /> },
  { path: "/Conta", element: <Conta /> },
  { path: "/Conta/Novo", element: <RegistroConta /> },
  { path: "/Conta/:id", element: <RegistroConta /> },
  { path: "/PlanoPagamento", element: <PlanoPagamento /> },
  { path: "/PlanoPagamento/Novo", element: <RegistroPlanoPagamento /> },
  { path: "/PlanoPagamento/:id", element: <RegistroPlanoPagamento /> },

  { path: "/Receita", element: <ReceitaTitulo /> },
  { path: "/Receita/Novo", element: <RegistroReceitaTitulo /> },
  { path: "/Receita/:id", element: <RegistroReceitaTitulo /> },
  { path: "/Receita/Registrar", element: <ListaReceitaTituloRegistar /> },
  { path: "/Receita/Registrar/:id", element: <ReceitaTituloRegistar /> },
  { path: "/Receita/Cancelar", element: <ListaReceitaTituloCancelar /> },
  { path: "/Receita/Cancelar/:id", element: <ReceitaTituloCancelar /> },
  { path: "/Receita/Estornar", element: <ListaReceitaTituloEstornar /> },
  { path: "/Receita/Estornar/:id", element: <ReceitaTituloEstornar /> },
  { path: "/Receita/Confirmar", element: <ListaReceitaTituloConfirmar /> },
  { path: "/Receita/Confirmar/:id", element: <ReceitaTituloConfirmar /> },

  { path: "/Ordem", element: <Ordem /> },
  { path: "/Ordem/Novo", element: <RegistroOrdem /> },
  { path: "/Ordem/:id", element: <RegistroOrdem /> },
  { path: "/Ordem/Faturar/:id", element: <OrdemFaturar /> },
  { path: "/Ordem/Cancelar/:id", element: <OrdemCancelar /> },

  { path: "/Despesa", element: <DespesaTitulo /> },
  { path: "/Despesa/Novo", element: <RegistroDespesaTitulo /> },
  { path: "/Despesa/:id", element: <RegistroDespesaTitulo /> },
  { path: "/Despesa/Registrar", element: <ListaDespesaTituloRegistar /> },
  { path: "/Despesa/Registrar/:id", element: <DespesaTituloRegistar /> },
  { path: "/Despesa/Cancelar", element: <ListaDespesaTituloCancelar /> },
  { path: "/Despesa/Cancelar/:id", element: <DespesaTituloCancelar /> },
  { path: "/Despesa/Estornar", element: <ListaDespesaTituloEstornar /> },
  { path: "/Despesa/Estornar/:id", element: <DespesaTituloEstornar /> },

  { path: "/Trator", element: <VeiculoTrator /> },
  { path: "/Trator/Novo", element: <RegistroTrator /> },
  { path: "/Trator/:id", element: <RegistroTrator /> },
  { path: "/Carreta", element: <Carreta /> },
  { path: "/Carreta/Novo", element: <RegistroCarreta /> },
  { path: "/Carreta/:id", element: <RegistroCarreta /> },
  { path: "/Marca", element: <Marca /> },
  { path: "/Marca/Novo", element: <RegistroMarca /> },
  { path: "/Marca/:id", element: <RegistroMarca /> },
  { path: "/FichaTecnica", element: <FichaTecnica /> },
  { path: "/FichaTecnica/Novo", element: <RegistroFichaTecnica /> },
  { path: "/FichaTecnica/:id", element: <RegistroFichaTecnica /> },
  { path: "/FichaTecnica/Copiar/:id", element: <RegistroFichaTecnica /> },
  { path: "/Filial", element: <Filial /> },
  { path: "/Filial/Novo", element: <RegistroFilial /> },
  { path: "/Filial/:id", element: <RegistroFilial /> },
  { path: "/TabelaPreco", element: <TabelaPreco /> },
  { path: "/TabelaPreco/Novo", element: <RegistroTabelaPreco /> },
  { path: "/TabelaPreco/:id", element: <RegistroTabelaPreco /> },

  { path: "/Orcamento", element: <Orcamento /> },
  { path: "/Orcamento/Novo", element: <RegistroOrcamento /> },
  { path: "/Orcamento/:id", element: <RegistroOrcamento /> },
  { path: "/Orcamento/Cancelar/:id", element: <CancelarOrcamento /> },

  { path: "/AET", element: <AET /> },
  { path: "/AET/Novo", element: <RegistroAET /> },
  { path: "/AET/:id", element: <RegistroAET /> },
  { path: "/AET/Copiar/:id", element: <RegistroAET /> },

  { path: "/Relatorio/:id", element: <Relatorio /> },
  { path: "/Trecho", element: <Trecho /> },
  { path: "/Trecho/Novo", element: <RegistroTrecho /> },
  { path: "/Trecho/:id", element: <RegistroTrecho /> },

  { path: "/Empresa", element: <Empresa /> },
  { path: "/Papel", element: <Papel /> },
  { path: "/Papel/Novo", element: <RegistroPapel /> },
  { path: "/Papel/:id", element: <RegistroPapel /> },
  { path: "/ART", element: <ART /> },
  { path: "/ART/Novo", element: <RegistroART /> },
  { path: "/ART/:id", element: <RegistroART /> },

  { path: "/Configuracao", element: <Configuracao /> },

  { path: "/Pessoa", element: <Pessoa /> },
  { path: "/Pessoa/Novo", element: <RegistroPessoa /> },
  { path: "/Pessoa/:id", element: <RegistroPessoa /> },

  { path: "/Credito", element: <Credito /> },
  { path: "/Credito/Novo", element: <RegistroCredito /> },
  { path: "/Credito/:id", element: <RegistroCredito /> },
  { path: "/Faturamento", element: <Faturamento /> },
  { path: "/Faturamento/:id", element: <RegistroFaturamento /> },
  { path: "/Faturamento/Cliente", element: <ListaFaturar /> },
];

export default autenticadoRoutes;
