import classNames from "classnames";
import React, {
  ReactNode,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { PermissaoMapa } from "src/PermissaoMapa";
import { Form } from "src/SmartR";
import { mapToCssModules } from "src/SmartR/Utils/utils";
import gomelius from "src/api/gomelius";

import {
  BotaoFiltroLista,
  BotaoNovoRegistro,
  PaginaLayout,
  TableColumnProps,
} from "src/components";

import TableContainer, {
  TableContainerRef,
} from "src/components/Common/TableContainer";
import { useDropdownContext } from "src/components/atoms/Dropdown";
import { AdminUtil } from "src/util";
export interface PaginaListaRef {
  updateFilter: (form: any) => void;
}

interface PaginaListaProps {
  item: string;
  titulo: string;
  modulo: string;
  urlNovo?: string;
  urlFetch?: string;
  urlDelete?: string;
  urlReativar?: string;
  urlInativar?: string;
  urlRouteEdit?: string;
  children?: any;
  filtroState?: any;
  colunas?: TableColumnProps[];
  colunasDetalhe?: TableColumnProps[];
  propriedadeDetalhe?: string;
  mostrarCabecalho?: boolean;
  mostrarCabecalhoDetalhe?: boolean;
  habilitarEfeitoHover?: boolean;
  habilitarEfeitoHoverDetalhe?: boolean;
  mostrarRegistroUnicoTabela?: boolean;
  cabecalhoUnicoRegistroUnico?: boolean;
  funcao: number;
  codigoPermissaoNovo?: number;
  formClassName?: string;
  tabelaClassName?: string;
  tabelaDetalheClassName?: string;
  onNovoHandle?: any;
  onRegistroCarregado?: any;
  onCliqueDuploLinha?: (rowData: any, rowIndex: number) => void;
  onCliqueDuploLinhaDetalhe?: (
    rowData: any,
    rowDataParent: any,
    rowIndex: number,
    rowParentIndex: number
  ) => void;
  tableRef?: React.Ref<TableContainerRef>; // Adicionando a prop `tableRef`
  renderHeader?: ReactNode;
  renderFooter?: ReactNode;
}

export const PaginaLista = React.forwardRef<PaginaListaRef, PaginaListaProps>(
  (props, ref) => {
    const {
      funcao,
      codigoPermissaoNovo,
      titulo,
      modulo,
      item,
      urlNovo,
      urlDelete,
      urlFetch,
      urlInativar,
      urlReativar,
      urlRouteEdit,
      colunas,
      colunasDetalhe,
      propriedadeDetalhe,
      mostrarCabecalho = true,
      mostrarCabecalhoDetalhe = true,
      habilitarEfeitoHover = true,
      habilitarEfeitoHoverDetalhe = true,
      mostrarRegistroUnicoTabela = false,
      cabecalhoUnicoRegistroUnico = false,
      children,
      formClassName = "form-w-sm",
      tabelaClassName,
      tabelaDetalheClassName,
      onNovoHandle,
      filtroState,
      onCliqueDuploLinha,
      onCliqueDuploLinhaDetalhe,
      tableRef,
      renderHeader,
      renderFooter,
    } = props;

    const navigate = useNavigate();
    const { setOpen } = useDropdownContext();
    const [permissaoNovo, setPermissaoNovo] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [filtro, setFiltro] = useState(filtroState);

    const handleSubmit = (data: any) => {
      setFiltro(data);
    };

    const handleDoubleClick = (rowData: any, rowIndex: number) => {
      if (onCliqueDuploLinha) {
        onCliqueDuploLinha(rowData, rowIndex);
      } else if (urlRouteEdit) {
        const route = `${urlRouteEdit}/${rowData.id}`;
        navigate(route);
      }
    };

    const classes = mapToCssModules(
      classNames(tabelaClassName, "context-menu")
    );
    useImperativeHandle(ref, () => ({
      updateFilter: (form: any) => {
        setFiltro(form);
      },
    }));
    useEffect(() => {
      if (codigoPermissaoNovo && codigoPermissaoNovo > 0) {
        // Verifica as permissões do usuário
        const usuarioAdministrador = gomelius.usuarioAdministrador();
        if (!usuarioAdministrador) {
          const permissoesUsuario = gomelius.obterPermissoes();
          if (
            !permissoesUsuario.includes(
              PermissaoMapa.obterPosicaoBit(codigoPermissaoNovo)
            )
          ) {
            setPermissaoNovo(false);
          }
        }
      }
    }, [codigoPermissaoNovo]);

    return (
      <React.Fragment>
        <PaginaLayout
          funcao={funcao}
          titulo={titulo}
          modulo={modulo}
          item={item}
          temFavorito={true}
        >
          {!isLoading ? (
            <>
              {permissaoNovo && (urlNovo || onNovoHandle) ? (
                <BotaoNovoRegistro
                  onClickHandle={onNovoHandle}
                  path={urlNovo}
                />
              ) : null}
              {children ? (
                <BotaoFiltroLista>
                  <Form
                    className={`px-4 py-3 filter-form ${formClassName}`}
                    onSubmit={handleSubmit}
                  >
                    {children}
                    <button type="submit" className="btn btn-primary float-end">
                      Confirmar
                    </button>
                  </Form>
                </BotaoFiltroLista>
              ) : null}
            </>
          ) : null}
          {renderHeader}
          <div className="card">
            <div className="card-body">
              <TableContainer
                ref={tableRef} // Passando `tableRef` para o `TableContainer`
                className={classes}
                classNameDetail={tabelaDetalheClassName}
                columns={colunas}
                columnsDetail={colunasDetalhe}
                dataDetailProperty={propriedadeDetalhe}
                showHeader={mostrarCabecalho}
                showDetailHeader={mostrarCabecalhoDetalhe}
                enableHoverEffect={habilitarEfeitoHover}
                enableHoverEffectDetail={habilitarEfeitoHoverDetalhe}
                singleRecordTable={mostrarRegistroUnicoTabela}
                uniqueHeaderSingleRecordTable={cabecalhoUnicoRegistroUnico}
                filterForm={filtro}
                urlFetch={urlFetch}
                urlDelete={urlDelete}
                urlInactive={urlInativar}
                urlReactive={urlReativar}
                onDoubleClick={handleDoubleClick}
                onDoubleClickDetail={onCliqueDuploLinhaDetalhe}
                timeZone={AdminUtil.obterFusoHorario()}
                culture="pt-BR"
                onLoadingStart={() => {
                  setIsLoading(true);
                  setOpen(false);
                }}
                onLoadingEnd={() => {
                  setIsLoading(false);
                }}
              />
            </div>
          </div>
        </PaginaLayout>
      </React.Fragment>
    );
  }
);
